import axios from 'axios'

export default function request(config){

    const baseURL =  'https://o.yushengtong.cn/'

    const instance = axios.create({
        baseURL:baseURL,
    })

    if((config.url === 'staff_login') || (config.url === 'phone_login_code') || (config.url === 'phone_login')) {
        //响应拦截
        instance.interceptors.response.use(success=>{
            if((success.data.status === 0) && (success.headers['x-token'])){
                sessionStorage['x-token'] = success.headers['x-token'];
            }
            return success.data
        },err=>{
            return err
        })
    } else if((config.url === 'we_chat/poll') || (config.url === 'we_chat/qr_code')) {
        if(sessionStorage.getItem('x-token')) { //如果已经登录
            //请求拦截
            instance.interceptors.request.use(success=>{
                success.headers['Authorization'] = 'Bearer ' + sessionStorage.getItem('x-token')
                return success
            },err=>{
                return err
            })

            //响应拦截
            instance.interceptors.response.use(success=>{
                return success.data
            },err=>{
                return err
            })
        } else {    //没有登录
            //响应拦截
            instance.interceptors.response.use(success=>{
                if((success.data.status === 1) && (success.headers['x-token'])){
                    sessionStorage['x-token'] = success.headers['x-token'];
                }
                return success.data
            },err=>{
                return err
            })
        }
    } else {
        if(!sessionStorage.getItem('x-token')) {
            //跳转到登录页面
            location.replace('/login')
        }

        //请求拦截
        instance.interceptors.request.use(success=>{
            success.headers['Authorization'] = 'Bearer ' + sessionStorage.getItem('x-token')
            return success
        },err=>{
            return err
        })

        //响应拦截
        instance.interceptors.response.use(success=>{
            return success.data
        },err=>{
            return err
        })
    }



    
    return instance(config)
}